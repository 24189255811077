import defaultTheme from '@client/css-modules/ImageCTAOrLenderCTA.css';
import {
  useLenderCtaUrlModifier,
  useLenderImageCtaUrl,
} from '@client/hooks/lender-cta-config.hooks';
import { useNativeAppLenderCTAClick } from '@client/hooks/native-app-integration.hooks';
import { useOutboundCtaUrl } from '@client/hooks/outbound-cta-url.hooks';
import { useWindowResize } from '@client/hooks/use-window-resize.hooks';
import { EVENTS, PARENT_EVENTS } from '@client/store/analytics-constants';
import {
  getCobrandDisplayName,
  getIsInsideNativeApp,
  getIsWellsFargoCobrand,
} from '@client/store/selectors/cobranding.selectors';
import { getImageCTAs } from '@client/store/selectors/image-ctas.selectors';
import {
  getIsMobile,
  getIsTabletSize,
} from '@client/store/selectors/match-media.selectors';
import { AnalyticsLenderCTAsAPI } from '@client/store/types/analytics';
import { ImageCtaScreenType } from '@client/store/types/image-ctas';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

type Props = {
  theme: Theme;
  area: 'pdp' | 'srp' | 'ho' | 'hic';
  ordinal?: number;
  LenderCTA: JSX.Element;
};

interface Dimensions {
  width: number;
  height: number;
}

const STD_IMG_WRAPPER_HEIGHT_PDP = 275;

const ImageCTAOrLenderCTA: React.FC<Props> = ({
  theme,
  area,
  ordinal,
  LenderCTA,
}) => {
  const isMobile = useSelector(getIsMobile);
  const isTablet = useSelector(getIsTabletSize);
  const isInsideNativeApp = useSelector(getIsInsideNativeApp);
  const nativeAppLenderCTAClick = useNativeAppLenderCTAClick();
  const screen: ImageCtaScreenType = isMobile
    ? 'mobile'
    : isTablet
      ? 'tablet'
      : 'desktop';
  const imageCTAWrapperRef = useRef<HTMLDivElement>(null);
  const [imageDimensions, setImageDimensions] = useState<Dimensions | null>(
    null
  );
  const [imageWrapperDimensions, setImageWrapperDimensions] =
    useState<Dimensions | null>(null);
  const [shouldDisplayImageFullCover, setShouldDisplayImageFullCover] =
    useState<boolean>(false);
  const cobrandDisplayName = useSelector(getCobrandDisplayName);
  const isWellsFargoCobrand = useSelector(getIsWellsFargoCobrand);
  const anchorAriaLabel = isWellsFargoCobrand
    ? 'Contact Wells Fargo Home Mortgage'
    : `Contact ${cobrandDisplayName}`;

  const imageCTAs = useSelector(getImageCTAs);
  const imageUrl = imageCTAs?.[area]?.[screen]?.imageUrl;
  const ctaURL = imageCTAs?.[area]?.[screen]?.ctaUrl;
  const ctaId = imageCTAs?.[area]?.[screen]?.ctaId;
  const ctaTrackingId = imageCTAs?.[area]?.[screen]?.trackingId;
  const imageAltText = imageCTAs?.[area]?.[screen]?.imageAltText;
  const nativeAppNavKey = imageCTAs?.[area]?.[screen]?.nativeAppNavKey;

  const setShouldDisplayImageFullCoverState = useCallback(
    (
      imageDimensions: Dimensions | null,
      imageWrapperDimensions: Dimensions | null
    ) => {
      if (
        imageWrapperDimensions?.width &&
        imageWrapperDimensions?.height &&
        imageDimensions?.width &&
        imageDimensions?.height
      ) {
        const imgAspectRatio = imageDimensions.width / imageDimensions.height;
        const cardAspectRatio =
          imageWrapperDimensions.width / imageWrapperDimensions.height;
        setShouldDisplayImageFullCover(
          imgAspectRatio.toFixed(2) === cardAspectRatio.toFixed(2)
        );
      }
    },
    []
  );

  const updateImageWrapperDimensions = useCallback(() => {
    if (imageCTAWrapperRef.current) {
      setImageWrapperDimensions({
        height:
          area === 'pdp'
            ? STD_IMG_WRAPPER_HEIGHT_PDP
            : imageCTAWrapperRef.current.offsetHeight,
        width: imageCTAWrapperRef.current.offsetWidth,
      });

      setShouldDisplayImageFullCoverState(
        imageDimensions,
        imageWrapperDimensions
      );
    }
  }, []);

  useWindowResize(updateImageWrapperDimensions);

  const loadImage = useCallback((setImageDimensions, imageUrl) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      setImageDimensions({
        height: img.height,
        width: img.width,
      });
    };
    img.onerror = (err) => {
      console.error(err);
    };
  }, []);

  useEffect(() => {
    if (imageUrl?.length && ctaURL) {
      loadImage(setImageDimensions, imageUrl);
      updateImageWrapperDimensions();
      setShouldDisplayImageFullCoverState(
        imageDimensions,
        imageWrapperDimensions
      );
    }
  }, [
    ctaURL,
    loadImage,
    updateImageWrapperDimensions,
    setShouldDisplayImageFullCoverState,
    imageUrl,
    imageDimensions?.width,
    imageDimensions?.height,
    imageWrapperDimensions?.width,
    imageWrapperDimensions?.height,
  ]);

  const modifiedCtaUrl = useLenderCtaUrlModifier(
    useLenderImageCtaUrl(area, screen)
  );
  const outboundCtaUrl = useOutboundCtaUrl(modifiedCtaUrl || null);
  if (!imageUrl?.length || !modifiedCtaUrl || !outboundCtaUrl) {
    return LenderCTA;
  }

  let cardWrapperClassName: string = theme.ImageCTAWrapper;

  if (area === 'ho') {
    cardWrapperClassName = theme.ImageCTAWrapperHODashboard;
  } else if (area === 'pdp') {
    cardWrapperClassName = theme.ImageCTAWrapperPDP;
    if (shouldDisplayImageFullCover) {
      cardWrapperClassName += ' ' + theme.ImageCTAWrapperPDPFullCover;
    }
  } else if (area === 'hic') {
    cardWrapperClassName += ' ' + theme.ImageCTAWrapperHIC;
  }

  const handleAdCardCTAClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (isInsideNativeApp && nativeAppNavKey) {
      nativeAppLenderCTAClick(nativeAppNavKey, {});
    } else {
      outboundCtaUrl && window.open(outboundCtaUrl, '_blank');
    }
  };

  const clickEventDataJson: AnalyticsLenderCTAsAPI = {
    area,
    image_name: imageUrl as string,
    cta_url: modifiedCtaUrl as string,
    cta_ordinal: ordinal,
    cta_id: ctaId as string,
    cta_tracking_id: ctaTrackingId,
  };

  return (
    <div className={cardWrapperClassName} ref={imageCTAWrapperRef}>
      <a
        aria-label={anchorAriaLabel}
        data-event-name={EVENTS.CLICK_LENDER_CTA}
        data-parent-event-name={PARENT_EVENTS.CLICK_LENDER_CTA}
        data-event-data-json={JSON.stringify(clickEventDataJson)}
        href={outboundCtaUrl}
        onClick={handleAdCardCTAClick}
        className={theme.ImageCTAAnchor}
      >
        <img
          src={imageUrl}
          alt=""
          className={classNames({
            [theme.ImageFullCover]: shouldDisplayImageFullCover,
            [theme.ImageFixedSize]: !shouldDisplayImageFullCover,
          })}
        />
      </a>
      {/* We are rendering the image twice because we discovered the image's alt announcements are inconsistent across screen reading tools, due to the surrounding anchor tag's aria-label attribute. To comply with audit findings, we apply a ScreenReaderVisuallyHidden CSS class to the second image to render an accessible, hidden image that won't be seen by sighted users. */}
      <img
        src={imageUrl}
        alt={imageAltText}
        className={theme.ScreenReaderVisuallyHidden}
      />
    </div>
  );
};

const ThemedImageCTAOrLenderCTA = themr(
  'ImageCTAOrLenderCTA',
  defaultTheme
)(ImageCTAOrLenderCTA);
export default ThemedImageCTAOrLenderCTA;
