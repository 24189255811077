import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import React from 'react';

import CobrandedStyles from '@client/components/CobrandedStyles';
import PillButton from '@client/components/generic/PillButton';
import defaultTheme from '@client/css-modules/CustomPropertyBanner.css';
import {
  useLenderCtaUrlModifier,
  useReplaceTemplateKeyWithLoId,
} from '@client/hooks/lender-cta-config.hooks';
import { AnalyticsDefaultLenderCTAForPDP } from '@client/store/types/analytics';
import { PropertyDetailsPageConfig } from '@client/store/types/cobranding';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';

type Props = {
  theme: Theme;
  isNarrowLayout: boolean;
  propertyPageData: PropertyDetailsPageConfig;
};

const CustomPropertyBanner: React.FC<Props> = ({
  theme,
  isNarrowLayout,
  propertyPageData,
}) => {
  const buttonText = propertyPageData?.bannerButtonLabel || undefined;
  const bodyText = propertyPageData?.bannerHeadline || undefined;
  const ctaUrl = propertyPageData?.bannerDestinationUrl;
  const urlTemplate = propertyPageData?.bannerLoUrlTemplate;
  const cta = useReplaceTemplateKeyWithLoId({
    // loUrlTemplate is from "LO-specific URL" field on Parcon
    urlTemplate,
    ctaUrl,
  });
  const modifiedCtaUrl = useLenderCtaUrlModifier(cta) || undefined;
  const onClick = () => {
    (propertyPageData.bannerDestinationUrl ||
      propertyPageData.bannerLoUrlTemplate) &&
      window.open(modifiedCtaUrl, '_blank', 'noopener');
  };
  const clickEventDataJson: AnalyticsDefaultLenderCTAForPDP = {
    area: 'pdp',
    cta_url: modifiedCtaUrl || '',
    button_copy: buttonText || '',
  };

  return (
    <>
      <CobrandedStyles>
        {({ partnerAgentBannerGradientColor, partnerAgentBackgroundColor }) => (
          <div
            className={classNames(theme.CustomPropertyBanner, {
              [theme.NarrowLayout]: isNarrowLayout,
            })}
            style={{
              background: partnerAgentBannerGradientColor,
              backgroundColor: partnerAgentBackgroundColor,
            }}
          >
            <div className={theme.TextColumn}>
              <div
                data-hc-name="custom-banner-title"
                className={theme.TitleCopy}
              >
                {bodyText}
              </div>
            </div>
            <div className={classNames(theme.CtaColumn)}>
              <PillButton
                dataHcName="custom-banner-button"
                data-event-name="click_cta_banner_pdp"
                data-event-data-json={JSON.stringify(clickEventDataJson)}
                data-parent-event-name="lender_ctas"
                ariaLabel={buttonText}
                className={theme.PillButton}
                onClick={onClick}
                onKeyDown={onEnterOrSpaceKey(onClick)}
              >
                {buttonText}
              </PillButton>
            </div>
          </div>
        )}
      </CobrandedStyles>
    </>
  );
};

export default themr(
  'ThemedCustomPropertyBanner',
  defaultTheme
)(CustomPropertyBanner);
