import { PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { call, put, select } from 'redux-saga/effects';

import {
  clearIsAgentFormPostedInCookie,
  putYourTeamReportAction,
} from '@client/components/YourTeam/utils/your-team.utils';
import { consumerApiClient } from '@client/services/consumer-api-client';
import { submitReferral } from '@client/store/sagas/referral-services.saga';
import {
  getUserHasFirstnameLastnameAndEmail,
  getUserId,
} from '@client/store/selectors/auth.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { getSelectedPropertyForReferSellerApi } from '@client/store/selectors/homeowner.selectors';
import { getLoanOfficerInfo } from '@client/store/selectors/loan-officer.selectors';
import { getSelectedPropertyForReferBuyerApi } from '@client/store/selectors/property-details.selectors';
import {
  getActivePDPSlug,
  getActiveSlugForHomeownerAndSubpages,
} from '@client/store/selectors/router.selectors';
import {
  cacheAgentData,
  contactLoanOfficerError,
  contactLoanOfficerSuccess,
  deleteAgentSelection,
  fetchAgentData,
  fetchAgentDataError,
  postContactAgent,
  postContactLoanOfficer,
  postFindAgentForm,
  postLenderAgentSelection,
  postPreviousAgentSelection,
  submitLODirectLeadMessage,
} from '@client/store/slices/your-team.slice';
import {
  ReferralFormValues,
  ReferralPropertyData,
} from '@client/store/types/property-contact-form';
import {
  ContactLoanOfficerActionPayload,
  FetchAgentDataPayload,
  LODirectLeadMessage,
  PreviousAgent,
} from '@client/store/types/your-team';
import { watchEvery } from '@client/utils/saga.utils';

export function* contactLoanOfficerSaga(
  action: ContactLoanOfficerActionPayload
) {
  const { lo_id, message } = action.payload;
  const userHasFirstnameLastnameAndEmail = (yield select(
    getUserHasFirstnameLastnameAndEmail
  )) as ReturnType<typeof getUserHasFirstnameLastnameAndEmail>;

  try {
    if (!userHasFirstnameLastnameAndEmail) {
      throw new Error(
        'Attempting to hit /your-team/contact/loan-officer without user firstname, lastname, or email'
      );
    }
    yield call(
      [consumerApiClient, consumerApiClient.contactYourTeamLoanOfficer],
      lo_id,
      message
    );

    yield put(contactLoanOfficerSuccess());
    yield putYourTeamReportAction('click_your_team_lo_send_success', {});
  } catch (e) {
    console.error(e);
    yield put(
      contactLoanOfficerError({
        error: 'Sorry, there was an error sending email, please try again.',
      })
    );
    yield putYourTeamReportAction('click_your_team_lo_send_error', {});
    // Throw error for Sentry reporting
    throw e;
  }
}

function* fetchLenderAgentSaga(loInfo: { loId: string; zipcode: string }) {
  try {
    const lenderAgent = yield call(
      [consumerApiClient, consumerApiClient.getYourTeamLenderAgent],
      loInfo.loId,
      loInfo.zipcode
    );
    yield put(
      cacheAgentData({
        agentType: 'lenderAgent',
        data: isEmpty(lenderAgent) ? null : lenderAgent,
      })
    );
  } catch (e) {
    yield put(fetchAgentDataError('lenderAgent'));
    throw new Error('Fetch lender agent failed: ' + JSON.stringify(e));
  }
}

function* fetchPreviousAgentSaga() {
  try {
    const previousAgent = yield call([
      consumerApiClient,
      consumerApiClient.getYourTeamPreviousAgent,
    ]);
    /* name and email/phone required for POST /connect/agent */
    const isValidPreviousAgentData =
      previousAgent?.name && (previousAgent?.email || previousAgent?.phone);
    yield put(
      cacheAgentData({
        agentType: 'previousAgent',
        data:
          isEmpty(previousAgent) || !isValidPreviousAgentData
            ? null
            : previousAgent,
      })
    );
  } catch (e) {
    yield put(fetchAgentDataError('previousAgent'));
    throw new Error('Fetch previous failed: ' + JSON.stringify(e));
  }
}

function* fetchGenericAgentSaga() {
  try {
    const genericAgent = yield call([
      consumerApiClient,
      consumerApiClient.getYourTeamGenericAgent,
    ]);
    const genericAgentData = isEmpty(genericAgent) ? null : genericAgent;
    yield put(
      cacheAgentData({ agentType: 'genericAgent', data: genericAgentData })
    );
  } catch (e) {
    yield put(fetchAgentDataError('genericAgent'));
    throw new Error('Fetch generic agent failed: ' + JSON.stringify(e));
  }
}

function* fetchConnectedAgentSaga() {
  try {
    const connectedAgent = yield call([
      consumerApiClient,
      consumerApiClient.getYourTeamConnectedAgent,
    ]);
    const connectedAgentData = isEmpty(connectedAgent) ? null : connectedAgent;
    yield put(
      cacheAgentData({ agentType: 'connectedAgent', data: connectedAgentData })
    );
    if (isEmpty(connectedAgent)) {
      const genericAgent = yield call([
        consumerApiClient,
        consumerApiClient.getYourTeamGenericAgent,
      ]);
      const genericAgentData = isEmpty(genericAgent) ? null : genericAgent;
      yield put(
        cacheAgentData({ agentType: 'genericAgent', data: genericAgentData })
      );
    }
    if (connectedAgentData) {
      clearIsAgentFormPostedInCookie(yield select(getUserId) ?? null);
    }
  } catch (e) {
    yield put(fetchAgentDataError('connectedAgent'));
    throw new Error('Fetch connected agent failed: ' + JSON.stringify(e));
  }
}

function* fetchAgentSaga(action: PayloadAction<FetchAgentDataPayload>) {
  const agentType = action.payload.agentType;

  if (agentType === 'genericAgent') {
    yield call(fetchGenericAgentSaga);
    return;
  }

  const isReferralServicesEnabled = (yield select(
    getIsFeatureEnabled('referral_services')
  )) as ReturnType<typeof getIsFeatureEnabled>;

  if (
    !isReferralServicesEnabled &&
    (agentType === 'previousAgent' || agentType === 'lenderAgent')
  ) {
    throw new Error(
      'Attempting to fetch unconnected agents with referral_services flag disabled, this is a no-op'
    );
  }

  switch (agentType) {
    case 'connectedAgent':
      yield call(fetchConnectedAgentSaga);
      break;
    case 'previousAgent':
      yield call(fetchPreviousAgentSaga);
      break;
    case 'lenderAgent':
      yield call(fetchLenderAgentSaga, action.payload.loInfo);
      break;
  }
}

function* postFindAgentFormSaga(
  action: PayloadAction<{ formValues: ReferralFormValues }>
) {
  try {
    yield call(postReferralServices, action.payload.formValues);
  } catch (e) {
    throw new Error('Post Find Agent Form failed: ' + JSON.stringify(e));
  }
}

function* postReferralServices(
  formValues: ReferralFormValues,
  agentId?: string,
  incompleteAgentId?: string
) {
  const homebuyerSlug = (yield select(getActivePDPSlug)) as ReturnType<
    typeof getActivePDPSlug
  >;
  const homeownerSlug = (yield select(
    getActiveSlugForHomeownerAndSubpages
  )) as ReturnType<typeof getActiveSlugForHomeownerAndSubpages>;
  const activeSlug = homebuyerSlug || homeownerSlug;
  const selectedProperty: ReferralPropertyData | null = homeownerSlug
    ? yield select(getSelectedPropertyForReferSellerApi(homeownerSlug))
    : homebuyerSlug
      ? yield select(getSelectedPropertyForReferBuyerApi(homebuyerSlug))
      : null;

  try {
    yield call(
      submitReferral,
      {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        email: formValues.email,
        phone: formValues.phone,
        buyingOrSelling:
          formValues?.buyingOrSelling || homeownerSlug ? 'Selling' : 'Buying',
        ...(activeSlug && { entrySlug: activeSlug }),
        ...(activeSlug && { propertyDetailSlugs: [activeSlug] }),
        ...(selectedProperty && { selectedProperty }),
        ...(agentId && { agentId }),
        ...(incompleteAgentId && { incompleteAgentId }),
      },
      yield select(getLoanOfficerInfo),
      `/refer/${
        formValues?.buyingOrSelling === 'Selling' || homeownerSlug
          ? 'seller'
          : 'buyer'
      }`
    );
  } catch (e) {
    throw new Error(
      `Call to Pipedrive failed on creating a lead for ${
        agentId ? 'lender' : 'previous'
      } agent: ` + JSON.stringify(e)
    );
  }
}

function* postContactAgentSaga(
  action: PayloadAction<{ selectedCta: string; id: string }>
) {
  try {
    yield call(
      [consumerApiClient, consumerApiClient.contactYourTeamAgent],
      action.payload.id,
      action.payload.selectedCta
    );
  } catch (e) {
    throw new Error('Contact Agent failed: ' + JSON.stringify(e));
  }
}

function* postLenderAgentSelectionSaga(
  action: PayloadAction<{ formValues: ReferralFormValues; agentId: string }>
) {
  try {
    yield call(
      [consumerApiClient, consumerApiClient.postLenderAgentSelection],
      action.payload.agentId
    );
    yield call(
      postReferralServices,
      action.payload.formValues,
      action.payload.agentId
    );
  } catch (e) {
    yield put(fetchAgentDataError('connectedAgent'));
    throw new Error('Connect Lender Agent failed: ' + JSON.stringify(e));
  }
}

function* postPreviousAgentSelectionSaga(
  action: PayloadAction<{
    formValues: ReferralFormValues;
    agentData: PreviousAgent;
  }>
) {
  try {
    const apiResponse = yield call(
      [consumerApiClient, consumerApiClient.postPreviousAgentSelection],
      {
        incomplete_agent: {
          brokerage_name: action.payload.agentData?.brokerageName,
          email: action.payload.agentData?.email,
          full_name: action.payload.agentData?.name,
          license_number: action.payload.agentData?.licenseNumber,
          phone: action.payload.agentData?.phone,
          license_state: action.payload.agentData?.state,
        },
      }
    );
    if (apiResponse?.incomplete_agent_id || apiResponse?.agent_id) {
      yield call(
        postReferralServices,
        action.payload.formValues,
        apiResponse?.agent_id,
        apiResponse?.incomplete_agent_id
      );
    }
  } catch (e) {
    yield put(fetchAgentDataError('connectedAgent'));
    throw new Error('Connect Previous Agent failed: ' + JSON.stringify(e));
  }
}

function* deleteAgentSelectionSaga() {
  try {
    yield call([consumerApiClient, consumerApiClient.deleteAgentSelection]);
  } catch (e) {
    throw new Error('Delete agent selection failed: ' + JSON.stringify(e));
  }
}

export function* submitLODirectLeadMessageSaga(
  action: PayloadAction<LODirectLeadMessage>
) {
  yield call(
    [consumerApiClient, consumerApiClient.contactYourTeamLODirectUser],
    action.payload
  );
  yield putYourTeamReportAction('lodirect_yourteam_message_submit_success', {});
}

export default (sagaMiddleware) => {
  watchEvery(sagaMiddleware, {
    [postContactLoanOfficer.type]: contactLoanOfficerSaga,
    [fetchAgentData.type]: fetchAgentSaga,
    [postContactAgent.type]: postContactAgentSaga,
    [postLenderAgentSelection.type]: postLenderAgentSelectionSaga,
    [postPreviousAgentSelection.type]: postPreviousAgentSelectionSaga,
    [postFindAgentForm.type]: postFindAgentFormSaga,
    [deleteAgentSelection.type]: deleteAgentSelectionSaga,
    [submitLODirectLeadMessage.type]: submitLODirectLeadMessageSaga,
  });
};
