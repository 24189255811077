import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useReportYourTeamEvent } from '@client/components/YourTeam/utils/your-team.utils';
import { authModalShow } from '@client/store/actions/auth.actions';
import { STATUSES } from '@client/store/constants';
import { getIsLoggedIn } from '@client/store/selectors/auth.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import {
  getLoanOfficerData,
  getLoanOfficerInfo,
  getLoanOfficerIsLODirectUser,
} from '@client/store/selectors/loan-officer.selectors';
import {
  fetchAgentData,
  selectConnectedAgent,
  selectGenericAgent,
  selectLenderAgent,
  selectPreviousAgent,
  selectYourTeamModalType,
  setYourTeamModalType,
} from '@client/store/slices/your-team.slice';
import {
  AuthModalHeadingText,
  AuthModalSubHeadingText,
} from '@client/store/types/auth';
import { YourTeamModalType } from '@client/store/types/your-team';

type LOWithZipcode = {
  loId: string;
  zipcode: string;
};
const isLOWithZipcode = (loInfo: {
  loId: string;
  zipcode: string | null;
}): loInfo is LOWithZipcode => !!loInfo.zipcode;

/**
 * This hook is used as a glorified selector to package all data needed for YourTeam agent. The intention is to keep selectors simple and pure while this hook gets more complex tied a certain feature.
 */
export function useYourTeamAgentData() {
  /* connected means user selected agent but could be pending or intro_completed */
  const connectedAgentData = useSelector(selectConnectedAgent)?.data ?? null;
  /* generic means user has not selected an agent yet */
  const genericAgentData = useSelector(selectGenericAgent)?.data ?? null;
  /* complete means agent info is completely filled */
  const completeAgent = connectedAgentData?.agent ?? null;
  /* incomplete means agent info is lacking */
  const incompleteAgent = connectedAgentData?.incomplete_agent ?? null;
  /* intro_required stores whether this user/agent association requires a real-life introduction.
   * (Associations via referral services/Pipedrive do, while associations via the Partner API do not)
   * intro_complete stores whether the Pipedrive Lead has been converted to a Deal (stage3), indicating
   * that a real-life introduction has been made.
   * If intro_required is false, we assume that the real-life introduction has been made externally to HC */
  const isAgentIntroComplete = connectedAgentData?.intro_required
    ? connectedAgentData?.intro_completed
    : true;
  /* TODO: request complete agent to have full_name attr so below var can be removed */
  const selectedAgent =
    completeAgent || incompleteAgent
      ? {
          full_name:
            completeAgent?.first_name &&
            completeAgent?.last_name &&
            `${completeAgent?.first_name} ${completeAgent?.last_name}`,
          brokerage_name: completeAgent?.brokerage_name,
          license_number: completeAgent?.license_number,
          email: completeAgent?.brokerage_email ?? completeAgent?.email,
          phone: completeAgent?.brokerage_phone ?? completeAgent?.phone,
          ...incompleteAgent,
        }
      : null;

  return {
    pendingAgentData: isAgentIntroComplete ? null : selectedAgent,
    genericAgentData: genericAgentData ?? null,
    introducedAgentData: isAgentIntroComplete ? completeAgent : null,
    lenderAgentData: useSelector(selectLenderAgent)?.data ?? null,
    previousAgentData: useSelector(selectPreviousAgent)?.data ?? null,
    lenderAgentFetchStatus: useSelector(selectLenderAgent)?.fetchStatus,
    previousAgentFetchStatus: useSelector(selectPreviousAgent)?.fetchStatus,
    connectedAgentFetchStatus: useSelector(selectConnectedAgent)?.fetchStatus,
  };
}

/**
 * This hook is used to open up YT modal using redux in places like the YT landing page and the regular YT modal click.
 */
export function useYourTeamModalState() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const allowUnauthenticatedAccess = useSelector(
    getIsFeatureEnabled('your_team_unauthenticated')
  );
  const isReferralServicesOn = useSelector(
    getIsFeatureEnabled('referral_services')
  );
  const { introducedAgentData, genericAgentData } = useYourTeamAgentData();
  const reportYourTeamEvent = useReportYourTeamEvent();
  const loanOfficerIsLODirectUser = useSelector(getLoanOfficerIsLODirectUser);

  return {
    isAgentTabEnabled:
      genericAgentData ||
      ((introducedAgentData || genericAgentData || isReferralServicesOn) &&
        (isLoggedIn || (allowUnauthenticatedAccess && introducedAgentData))),
    isYourTeamModalOpen: !!useSelector(selectYourTeamModalType),
    openYourTeamModal: function (
      modalType: YourTeamModalType,
      headingTextConfig?: {
        subHeadingText: AuthModalSubHeadingText;
        headingText: AuthModalHeadingText;
      }
    ) {
      reportYourTeamEvent('click_your_team_button', {});

      /* Allow access without prompting for login in certain cases */
      if (
        isLoggedIn ||
        /* Allow unauthenticated access to LO tab if the FF is enabled. We'll prompt for authentication
         * later in the flow, when the user attempts to send a message to the LO */
        (allowUnauthenticatedAccess && modalType === 'lo') ||
        /* Only allow unauthenticated access to agent tab if user has an assigned (and 'introduced' if intro_required=true) agent.
         * In this case, we'll prompt for authentication later in the flow, when the user attempts to send a message
         * to the agent */
        (allowUnauthenticatedAccess &&
          modalType === 'agent' &&
          introducedAgentData) ||
        /* Allow unauthenticated access to LO tab, regardless of the FF, if user is assigned to an LO Direct user.
         * In this case we'll prompt the user to authenticate later, when they attempt to send a message to the LO. */
        (loanOfficerIsLODirectUser && modalType === 'lo')
      ) {
        dispatch(setYourTeamModalType({ modalType }));
        return;
      } else {
        dispatch(
          authModalShow({
            startingPage: 'login',
            afterAuthAction: setYourTeamModalType({ modalType }),
            ...headingTextConfig,
          })
        );
      }
    },
    closeYourTeamModal: function () {
      reportYourTeamEvent('click_your_team_close_button', {});
      dispatch(setYourTeamModalType({ modalType: null }));
    },
  };
}

export function useIsLOTabDisabled() {
  const isGenericLOTabDisabled = useSelector(
    getIsFeatureEnabled('your_team_disable_generic_lo_tab')
  );
  const loanOfficer = useSelector(getLoanOfficerData);

  return isGenericLOTabDisabled && !loanOfficer;
}

/**
 * This effect hook is used whenever a component needs to fetch agent data for YourTeam experience (i.e. YT landing page and YT modal opening).
 */
export function useYourTeamAgentDataFetchEffect(runEffectOnMount?: boolean) {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const loInfo = useSelector(getLoanOfficerInfo);
  const isReferralServicesEnabled = useSelector(
    getIsFeatureEnabled('referral_services')
  );
  const isYourTeamModalOpen = !!useSelector(selectYourTeamModalType);
  const { lenderAgentFetchStatus } = useYourTeamAgentData();

  useEffect(
    function () {
      if (!isLoggedIn) {
        dispatch(fetchAgentData({ agentType: 'genericAgent' }));
      } else if ((isYourTeamModalOpen || runEffectOnMount) && isLoggedIn) {
        dispatch(fetchAgentData({ agentType: 'connectedAgent' }));
        if (isReferralServicesEnabled) {
          dispatch(fetchAgentData({ agentType: 'previousAgent' }));
        }
        /* Only fetch lender agent if we have a user-associated loan officer */
        if (
          isReferralServicesEnabled &&
          lenderAgentFetchStatus === STATUSES.INIT &&
          loInfo &&
          isLOWithZipcode(loInfo)
        ) {
          dispatch(fetchAgentData({ agentType: 'lenderAgent', loInfo }));
        }
      }
    },
    runEffectOnMount
      ? []
      : [isYourTeamModalOpen, loInfo, isLoggedIn, isReferralServicesEnabled]
  );
}

export function useLoDirectLenderCtaClick() {
  const { openYourTeamModal } = useYourTeamModalState();
  const isYourTeamEnabled = useSelector(getIsFeatureEnabled('your_team'));
  const isLODirectEnabled = useSelector(getIsFeatureEnabled('lo_direct'));
  const loanOfficerIsLODirectUser = useSelector(getLoanOfficerIsLODirectUser);

  if (isLODirectEnabled && loanOfficerIsLODirectUser && isYourTeamEnabled) {
    return function () {
      openYourTeamModal('lo', {
        headingText: {
          login: 'Submit your message',
          'sign-up': 'Submit your message',
        },
        subHeadingText: {
          login: 'Please log in to your account',
          'sign-up': 'Please sign up',
        },
      });
    };
  } else {
    return null;
  }
}
