/**
 * Keep top-level keys in alpha order please
 */
export default ({
  slug,
  photoSize,
}: {
  slug: string;
  photoSize: 'SMALL' | 'MEDIUM' | 'LARGE';
}) => ({
  query: /* GraphQL */ `
    query PropertyDetailsInit($slug: String, $photoSize: ImageSize) {
      propertyLookup(id: { slug: $slug }) {
        address {
          slug
          fullAddress
          hcAddressId
          streetAddress
          unit
          city
          state
          zipcode
          zipcodePlus4
        }
        association {
          name
          fees {
            amount
            frequency
            notes
          }
        }
        avm(qualityMethod: CONSUMER) {
          priceMean
          fsd
        }
        basement {
          has
          sqftFinishedPercent
          sqftTotal
        }
        bestPhotos(orderBy: MLS) {
          id
          storageUrl
          prediction
          confidence
          representation(size: $photoSize) {
            httpUrl
            height
            width
          }
        }
        block {
          tractId
          crime {
            all {
              countyPercentile
            }
          }
          valueTsForecastSummary {
            year
            value
            percent
          }
          histograms {
            buildingArea(rebin: { bins: 5, method: TAILS }) {
              count
              end
              start
            }
            valuePerSqft(rebin: { bins: 5, method: TAILS }) {
              count
              end
              start
            }
          }
        }
        hcBuildingId
        comps(limit: 6, minScore: 85, includeActive: false) {
          avm {
            priceMean
          }
          address {
            slug
            fullAddress
            hcAddressId
            streetAddress
            unit
            city
            state
            zipcode
            zipcodePlus4
          }
          geoLocation {
            latitude
            longitude
          }
        }
        county {
          name
        }
        geoLocation {
          latitude
          longitude
        }
        hasPool
        latestAssessment {
          taxSummary {
            amount
            year
          }
        }
        latestListing {
          agentName
          agentEmail
          agentPhone
          agentLicense
          listingOfficeName
          buyerBrokerageCompensationDisplay
          openHouse(status: ACTIVE) {
            appointmentRequired
            date
            description
            directions
            end
            remarks
            start
          }
          publicRemarks
          status
          statusDate
          price
          listingID
        }
        listDate
        listPrice
        livingSpace {
          numberOfRooms
          livingArea
          bedrooms {
            count
          }
          bathrooms {
            summaryCount
          }
        }
        mls {
          mlsID
          name
          abbreviation
          lastRefreshed
          regulations {
            copyrightStatement
            disclaimer
            logo
            logoOverlay
            photosClosedLogin
            photosLogoOverlay
          }
        }
        mlsState
        propertyType
        paymentEstimate {
          total
        }
        rentalAvm(qualityMethod: CONSUMER) {
          priceMean
        }
        schools {
          id
          name
          levels
          distanceMiles
          score
        }
        schoolsSummary
        site {
          area(units: SQFT)
          areaDescription
          zoning {
            code
          }
        }
        structure {
          yearBuilt
          stories
        }
        transfers(order: DESCENDING) {
          transferDate
          transferPrice
          eventType
        }
        zip {
          hpiForecastSummary {
            year
            value
            percent
          }
        }
      }
    }
  `,
  variables: {
    slug,
    photoSize,
  },
});
