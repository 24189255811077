import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import { useDispatch, useSelector } from 'react-redux';

import ContactLOButton from '@client/components/ContactLOButton';
import GenericLOInfo from '@client/components/GenericLOInfo';
import LOContactInfo from '@client/components/LOContactInfo';
import defaultTheme from '@client/css-modules/PDPLoanOfficerAd.css';
import { useAutoSaveProperty } from '@client/hooks/auto-save-property.hooks';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { useLoInfoProps } from '@client/hooks/lo-info-provider.hooks';
import { openModal } from '@client/store/actions/modals.actions';
import { EVENTS, PARENT_EVENTS } from '@client/store/analytics-constants';
import { getCustomizationData } from '@client/store/selectors/cobranding.selectors';

type Props = {
  theme: Theme;
};

/*
  loCard.hbPdp
*/
const PDPLoanOfficerAdLendco = ({ theme }: Props) => {
  const dispatch = useDispatch();
  const autoSaveProperty = useAutoSaveProperty();
  const { external_url_for_cta_btn_on_lo_card_in_pdp } =
    useSelector(getCustomizationData);
  const { loanOfficerBg, pillButtonPrimaryBackgroundColor } =
    useCobrandStyles();
  const { lastMessageDate, loConfig, loData, showLOCard, getLOCardIntroText } =
    useLoInfoProps();

  const openContactModal = () => {
    autoSaveProperty();
    dispatch(openModal('contact-loan-officer'));
  };

  if (!showLOCard) {
    return null;
  } else {
    // loConfig is undefined for ComeHome, chase cobrands
    const hbPdp = loConfig?.hbPdp;
    const contactButtonText = loData?.firstName ?? loData?.fullName ?? 'Us';
    const headline = hbPdp?.headline ?? 'Finance your next home purchase.';
    const introText = getLOCardIntroText(
      hbPdp?.enableIntroText,
      hbPdp?.introText,
      hbPdp?.introAltText
    );
    const buttonFill = hbPdp?.buttonColor ?? pillButtonPrimaryBackgroundColor;
    const bgColor = hbPdp?.bgColor ?? loanOfficerBg;
    const dataEventName = external_url_for_cta_btn_on_lo_card_in_pdp
      ? 'click_url_cta_pdp'
      : EVENTS.CLICK_BUYER_PDP_CONTACT;
    const dataParentEventName = external_url_for_cta_btn_on_lo_card_in_pdp
      ? PARENT_EVENTS.CLICK_URL_CTA
      : PARENT_EVENTS.CLICK_LO_CONTACT;

    return (
      <div style={{ background: bgColor }} className={theme.PDPLoanOfficerAd}>
        {
          <>
            {introText && <div className={theme.HeadlineCopy}>{introText}</div>}
            <div role="heading" aria-level={1} className={theme.AdCopy}>
              {headline}
            </div>
            <ContactLOButton
              theme={theme}
              style={{ background: buttonFill }}
              buttonText={contactButtonText}
              buttonCTAUrl={external_url_for_cta_btn_on_lo_card_in_pdp}
              data-event-name={dataEventName}
              data-parent-event-name={dataParentEventName}
              handleClickWithoutExternalCtaLink={openContactModal}
            />
          </>
        }
        {loData ? <LOContactInfo theme={theme} /> : <GenericLOInfo />}
        {lastMessageDate && (
          <div className={theme.LastMessageDate}>
            Last message sent on {lastMessageDate}
          </div>
        )}
      </div>
    );
  }
};

const ThemedPDPLoanOfficerAd = themr(
  'PDPLoanOfficerAd',
  defaultTheme
)(PDPLoanOfficerAdLendco);
export default ThemedPDPLoanOfficerAd;
