import { MlsState } from '@client/store/sagas/queries/types';
import { COBRAND_IDS, DomainTheme } from '@client/store/types/cobranding';
import { defaultCustomizationDataJSON } from '@hc/consumer-customization-data';

/**
 * A place to define app-wide constants
 */
export const STATUSES = {
  INIT: 'INIT' as 'INIT',
  LOADING: 'LOADING' as 'LOADING',
  UPDATING: 'UPDATING' as 'UPDATING',
  SUCCESS: 'SUCCESS' as 'SUCCESS',
  DOES_NOT_EXIST: 'DOES_NOT_EXIST' as 'DOES_NOT_EXIST',
  DISMISSED: 'DISMISSED' as 'DISMISSED',
  ERROR: 'ERROR' as 'ERROR',
  EXPIRED: 'EXPIRED' as 'EXPIRED',
  FAILED: 'FAILED' as 'FAILED',
};

export type Status = (typeof STATUSES)[keyof typeof STATUSES];

export const REDIRECTING_ERROR = 'REDIRECTING_ERROR';

export const CONFIDENCE_LEVELS = {
  HIGH: 'high' as 'high',
  AVERAGE: 'average' as 'average',
  LOW: 'low' as 'low',
};

export const NULL_STATE_TEXT = {
  VALUE:
    "We don't have sufficient data to calculate this information right now — but our data improves every day. Please check again later!",
  SECTION_PREVIEW:
    'Insufficient data available. We are improving our data every day; please check back later',
};

export type ForecastChartType =
  (typeof FORECAST_CHART_TYPES)[keyof typeof FORECAST_CHART_TYPES];

export const FORECAST_CHART_TYPES = {
  ZIP: 'ZIP' as 'ZIP',
  BLOCK: 'BLOCK' as 'BLOCK',
};

export const GRANT_TYPES = {
  USER: 'user' as 'user',
  ANONYMOUS_DEVICE: 'anonymous_device' as 'anonymous_device',
  INVITE: 'invite' as 'invite',
  LOUSER: 'louser' as 'louser',
};

export type GrantType = (typeof GRANT_TYPES)[keyof typeof GRANT_TYPES];

export const MAP_VIEW_TYPES = {
  LOT: 'LOT' as 'LOT',
  MAP: 'MAP' as 'MAP',
  STREET: 'STREET' as 'STREET',
};

export const NEIGHBORHOOD_INSIGHT_TYPES = {
  AGE: 'AGE' as 'AGE',
  BEDS: 'BEDS' as 'BEDS',
  BATHS: 'BATHS' as 'BATHS',
  VALUEPERSQFT: 'VALUEPERSQFT' as 'VALUEPERSQFT',
  BUILDINGAREA: 'BUILDINGAREA' as 'BUILDINGAREA',
};

export const NEIGHBORHOOD_INSIGHT_API_KEYS = {
  [NEIGHBORHOOD_INSIGHT_TYPES.AGE]: 'age',
  [NEIGHBORHOOD_INSIGHT_TYPES.BEDS]: 'beds',
  [NEIGHBORHOOD_INSIGHT_TYPES.BATHS]: 'baths',
  [NEIGHBORHOOD_INSIGHT_TYPES.VALUEPERSQFT]: 'valuePerSqft',
  [NEIGHBORHOOD_INSIGHT_TYPES.BUILDINGAREA]: 'buildingArea',
};

export const NEIGHBORHOOD_INSIGHT_TYPES_OPTIONS = [
  { label: 'Sq Ft', type: NEIGHBORHOOD_INSIGHT_TYPES.BUILDINGAREA },
  { label: '$/Sq Ft', type: NEIGHBORHOOD_INSIGHT_TYPES.VALUEPERSQFT },
  { label: 'Beds', type: NEIGHBORHOOD_INSIGHT_TYPES.BEDS },
  { label: 'Baths', type: NEIGHBORHOOD_INSIGHT_TYPES.BATHS },
  { label: 'Age', type: NEIGHBORHOOD_INSIGHT_TYPES.AGE },
];

export const PROPERTY_STATUSES: {
  [key in MlsState]: MlsState;
} = {
  ACTIVE: 'ACTIVE' as 'ACTIVE',
  CANCELLED: 'CANCELLED' as 'CANCELLED',
  CLOSED: 'CLOSED' as 'CLOSED',
  COMING_SOON: 'COMING_SOON' as 'COMING_SOON',
  CONTINGENT: 'CONTINGENT' as 'CONTINGENT',
  DELETED: 'DELETED' as 'DELETED',
  EXPIRED: 'EXPIRED' as 'EXPIRED',
  LEASED: 'LEASED' as 'LEASED',
  PENDING: 'PENDING' as 'PENDING',
  SOLD: 'SOLD' as 'SOLD',
  /* FYI this is not the same as `OFFMARKET` which is part of the `MlsStateGroup` type (i.e. a
   * group of types, not an individual type) */
  UNKNOWN: 'UNKNOWN' as 'UNKNOWN',
  WITHDRAWN: 'WITHDRAWN' as 'WITHDRAWN',
};

/* Properties with the following statuses are treated as active listings in our UI */
export const ACTIVE_LISTING_STATUSES = [
  'ACTIVE' as 'ACTIVE',
  'PENDING' as 'PENDING',
  'CONTINGENT' as 'CONTINGENT',
];

export enum RegressionType {
  LOCATION = 'LOCATIONREGRESSION',
  LIVINGAREA = 'LIVINGAREAREGRESSION',
  LOTSIZE = 'LOTSIZEREGRESSION',
  AGE = 'AGEREGRESSION',
  BEDROOMS = 'BEDROOMSREGRESSION',
  BATHROOMS = 'BATHROOMSREGRESSION',
  POOL = 'POOLREGRESSION',
  BASEMENT = 'BASEMENTREGRESSION',
}

/* Should match type in GraphQL response */
export const CRIME_TYPES = {
  ALL: 'all' as 'all',
  PROPERTY: 'property' as 'property',
  VIOLENT: 'violent' as 'violent',
};

export type CrimeTypes = (typeof CRIME_TYPES)[keyof typeof CRIME_TYPES];

export const CITY_LINKS_DATA_FOR_NEW_FOOTER = [
  { NAME: 'Tampa, FL', PLACESLUG: 'Tampa-FL' },
  { NAME: 'Orlando, FL', PLACESLUG: 'Orlando-FL' },
  { NAME: 'Miami, FL', PLACESLUG: 'Miami-FL' },
  { NAME: 'Atlanta, GA', PLACESLUG: 'Atlanta-GA' },
  { NAME: 'Chicago, IL', PLACESLUG: 'Chicago-IL' },
  { NAME: 'Phoenix, AZ', PLACESLUG: 'Phoenix-AZ' },
  { NAME: 'Wilmington, NC', PLACESLUG: 'Wilmington-NC' },
  { NAME: 'St. Louis, MO', PLACESLUG: 'St-Louis-MO' },
  { NAME: 'Seattle, WA', PLACESLUG: 'Seattle-WA' },
  { NAME: 'Raleigh, NC', PLACESLUG: 'Raleigh-NC' },
  { NAME: 'Durham, NC', PLACESLUG: 'Durham-NC' },
  { NAME: 'Winston-Salem, NC', PLACESLUG: 'Winston-Salem-NC' },
  { NAME: 'Knoxville, TN', PLACESLUG: 'Knoxville-TN' },
];

export type SchoolTypes = (typeof SCHOOL_TYPES)[keyof typeof SCHOOL_TYPES];
/* Should match type in GraphQL response */
export const SCHOOL_TYPES = {
  ELEMENTARY: 'ELEMENTARY' as 'ELEMENTARY',
  MIDDLE: 'MIDDLE' as 'MIDDLE',
  HIGH: 'HIGH' as 'HIGH',
};

export const TRANSFER_TYPES = {
  PURCHASE: 'PURCHASE' as 'PURCHASE',
  TRANSFER: 'TRANSFER' as 'TRANSFER',
  ADJUSTMENT: 'ADJUSTMENT' as 'ADJUSTMENT',
  LEASE: 'LEASE' as 'LEASE',
  FORECLOSURE: 'FORECLOSURE' as 'FORECLOSURE',
  REFINANCE: 'REFINANCE' as 'REFINANCE',
  OTHER: 'OTHER' as 'OTHER',
  DISTRESSED: 'DISTRESSED' as 'DISTRESSED',
};

export const PROPERTY_TYPES = {
  SINGLE_FAMILY_DETACHED: 'Single Family Detached',
  SINGLE_FAMILY_ATTACHED: 'Singe Family Attached',
  CONDO: 'Condo',
  VACANT_LOT: 'Vacant Lot',
  MANUFACTURED_HOME: 'Manufactured Home',
  OTHER: 'Other',
  MULTI_FAMILY: 'Multifamily',
  COMMERCIAL: 'Commercial',
  TIMESHARE: 'Timeshare',
  COOP: 'Co-op',
};

export const PROPERTY_SCHEMA_TYPES = {
  SINGLE_FAMILY_RESIDENCE: 'SingleFamilyResidence' as 'SingleFamilyResidence',
  HOUSE: 'House' as 'House',
  ACCOMMODATION: 'Accommodation' as 'Accommodation',
  APARTMENT: 'Apartment' as 'Apartment',
  ROOM: 'Room' as 'Room',
  SUITE: 'Suite' as 'Suite',
  RESIDENCE: 'Residence' as 'Residence',
  APARTMENT_COMPLEX: 'ApartmentComplex' as 'ApartmentComplex',
  PLACE: 'Place' as 'Place',
};

export const SETTINGS_KEYS = {
  SEND_EMAIL: 'send_email' as 'send_email',
  SEND_EMAIL_SEARCH: 'send_email_search' as 'send_email_search',
  SEND_MARKET_UPDATES: 'send_market_updates' as 'send_market_updates',
  SEND_MOBILE: 'send_mobile' as 'send_mobile',
  SEND_MOBILE_SEARCH: 'send_mobile_search' as 'send_mobile_search',
  SEND_INAPP_SAVED_HOME_NOTIF:
    'send_inapp_saved_home_notif' as 'send_inapp_saved_home_notif',
  SEND_INAPP_SAVED_SEARCHES_NOTIF:
    'send_inapp_saved_searches_notif' as 'send_inapp_saved_searches_notif',
  SEND_EMAIL_SEARCH_FREQUENCY:
    'send_email_search_frequency' as 'send_email_search_frequency',
};

export type SettingsKey = (typeof SETTINGS_KEYS)[keyof typeof SETTINGS_KEYS];

export const ALERT_TYPES = {
  PROPERTY_UPDATE: 'property_update' as 'property_update',
  SAVED_SEARCH_RESULT: 'saved_search_result' as 'saved_search_result',
};

export const AUTH_MODAL_PAGES = {
  LOGIN: 'login' as 'login',
  SIGN_UP: 'sign-up' as 'sign-up',
};

/* Used to refer to modals for opening modals and checking if modals are open */
export type ModalKey =
  | 'auth-modal'
  | 'avm-deep-dives-modal'
  | 'avm-deep-dives-tooltip'
  | 'brokerage-modal'
  | 'cant-find-address-modal'
  | 'ch-direct-client-upload'
  | 'cho-pro-finder-reviews'
  | 'comps'
  | 'comps-modal'
  | 'comps-modal-tooltip'
  | 'confirm-modal'
  | 'confirm-add-watchlist-modal'
  | 'confirm-claim-home-modal'
  | 'contact-agent'
  | 'contact-new-agent'
  | 'contact-loan-officer'
  | 'crime'
  | 'cta-agent-form'
  | 'data_deep_dives'
  | 'find-agent-error-modal'
  | 'find-agent-success-modal'
  | 'forecast'
  | 'homeowner-access-modal'
  | 'homeowner-avm-accessible-chart'
  | 'homeowner-compsales-modal'
  | 'homeowner-home-improve'
  | 'homeowner-home-refinance'
  | 'homeowner-home-sales-see-all'
  | 'homeowner-home-sales-map-view'
  | 'homeowner-home-value'
  | 'homeowner-pmi-modal'
  | 'homeowner-propensity-to-sell'
  | 'homeowner-search-modal'
  | 'invite-search-agreement-modal'
  | 'local-activities-modal'
  | 'mobile-filters-modal'
  | 'mortgage_calculator'
  | 'neighborhood_insights'
  | 'neighborhood-insights-text-summary-modal'
  | 'partner-agent-form'
  | 'photo-carousel-dialog'
  | 'photo-list'
  | 'pdp-mobile-breakout-section'
  | 'property-details'
  | 'recent-user-activity'
  | 'rental_avm'
  | 'resend-confirm-email-success-modal'
  | 'sale_history'
  | 'schools'
  | 'share-modal'
  | 'tooltip'
  | 'terms-and-conditions'
  | 'update-email-modal';

/* Used to render portal destination divs for modals to be rendered via portals */
export const MODAL_KEYS_TO_RENDER_IN_PORTAL: ModalKey[] = [
  'avm-deep-dives-modal',
  'avm-deep-dives-tooltip',
  'brokerage-modal',
  'ch-direct-client-upload',
  'cho-pro-finder-reviews',
  'comps-modal',
  'comps-modal-tooltip',
  'confirm-modal',
  'cta-agent-form',
  'find-agent-error-modal',
  'find-agent-success-modal',
  'homeowner-access-modal',
  'homeowner-avm-accessible-chart',
  'homeowner-compsales-modal',
  'homeowner-home-improve',
  'homeowner-home-value',
  'homeowner-search-modal',
  'homeowner-propensity-to-sell',
  'local-activities-modal',
  'mobile-filters-modal',
  'neighborhood-insights-text-summary-modal',
  'partner-agent-form',
  'photo-carousel-dialog',
  'photo-list',
  'pdp-mobile-breakout-section',
  'property-details',
  'share-modal',
  'terms-and-conditions',
  'tooltip',
  'contact-agent',
  'homeowner-pmi-modal',
];

export type ModalKeyToRenderInPortal =
  (typeof MODAL_KEYS_TO_RENDER_IN_PORTAL)[number];

export type NotificationKey =
  | 'request-a-tour-success'
  | 'shared-property-saved-notification'
  | 'shared-property-saved-email-setting-notification'
  | 'saved-search-notification'
  | 'saved-search-email-setting-notification'
  | 'saved-property-email-setting-notification'
  | 'home-verified-notification'
  | 'home-edit-success-notification';

export const SESSION_STORAGE_KEYS = {
  VISITED_PROPERTIES: 'visited-properties',
};

export const PDP_PUBLIC_OWNER_TOGGLE = 'pdppublicownertoggle';
export const SEARCH_MAP_LOCATION_URL_PARAM_KEY = 'loc';
export const SEARCH_MAP_SELECTED_ADDRESS_SLUG_URL_PARAM_KEY = 'slug';
export const SEARCH_MAP_MOBILE_FILTERS_SHOWN_URL_KEY = 'filters';
export const SEARCH_MAP_DESKTOP_FILTERS_SHOWN_URL_KEY = 'desktopfilters';
export const SEARCH_ACTIVE_VIEW_URL_PARAM_KEY = 'view';
export const SEARCH_ACTIVE_VIEW_URL_PARAM_OPTIONS = {
  LIST: 'list' as 'list',
  MAP: 'map' as 'map',
};
export const SEARCH_MAP_URL_PARAM_DELIMINATOR = ',';
export const ACTIVE_MODAL_URL_KEY = 'modal';
export const CANT_FIND_ADDRESS_MODAL_ACTIVE_KEY = 'addressform';
export const WELCOME_SEARCH_ONLY_ADDRESS_URL_KEY = 'address';
export const WELCOME_TRANSITION_INSET_ONLY_ADDRESS_URL_KEY = 'address';
export const BROKERAGE_ATTRIBUTION_MODAL_KEY = 'hcbrokerage';
export const LOAN_OFFICER_ID_URL_KEY = 'loid';
export const LOID_COOKIE_NAME = 'loid';
export const AGENT_ID_URL_KEY = 'agentid';
export const AGENT_ID_COOKIE_NAME = 'agentid';
export const TERMS_OF_USE_CONTENT_URL_KEY = 'content_url';
export const ACCESS_TOKEN_URL_KEY = 'access_token';
export const ORIGINAL_CTA_URL_KEY = 'url';

export const AB_TEST_MANUAL_OVERRIDE_LS_PREFIX =
  'ab-test-override-' as 'ab-test-override-';

interface ABTestExperimentType {
  [key: string]: string;
}

export const AB_TEST_EXPERIMENTS: ABTestExperimentType | {} = {};

export const AB_TESTS_VARIANTS = {
  // NOTE: leaving this here so we can see the structure needed for A/B tests:
  // [AB_TEST_EXPERIMENTS.FULLSCREEN_FILTERS]: {
  //   IS_FULLSCREEN: 'is-fullscreen',
  //   IS_NOT_FULLSCREEN: 'is-not-fullscreen'
  // }
};

export const SEARCH_CITY_ADDRESS_INPUT_PLACEHOLDER =
  'Search for a city, ZIP code or address';

export const COOKIE_KEYS = {
  AB_TESTS_COOKIE: 'abTests',
  AB_TESTS_DISABLE_ALL: 'abTestsDisableAll',
  WELCOME_MODAL_COOKIE: 'suppress_welcome_modal',
  SUPPRESS_EMAIL_UPDATE_BANNER: 'suppress_email_update_banner',
  SUPPRESS_LENDER_MODAL: 'suppress_lender_modal',
  LENDER_DOMAIN: 'lender_domain',
  LENDER_CAMPAIGN: 'lender_campaign',
  HAS_VIEWED_LENDER_WELCOME_MODAL: 'has_viewed_lender_modal',
  LENDER_MEDIUM: 'lender_medium',
  CHAPI_PARTNER_INVITE_ID: {
    [COBRAND_IDS.TOPAGENTSRANKED]: `chapi_pi_${COBRAND_IDS.TOPAGENTSRANKED}`,
  },
  YT2_AGENT_FORM_POSTED: 'yt2_agent_form_posted',
};

export const TUTORIAL_IDS = {
  HEATMAPS: 'heatmaps-tutorial',
  FILTERS: 'filters-tutorial',
  SAVE_SEARCH: 'save-search-tutorial',
  AVM_BREAKDOWN: 'avm-breakdown-tutorial',
  WATCHLIST_BUTTON: 'watchlist-button-tutorial',
};

export const ACTIVITY_IDS = {
  GROCERY: 'grocery' as 'grocery',
  RESTAURANTS: 'restaurants' as 'restaurants',
  BARS: 'bars' as 'bars',
  CAFES: 'cafes' as 'cafes',
  ENTERTAINMENT: 'entertainment' as 'entertainment',
  PARKS: 'parks' as 'parks',
  ALL: 'all' as 'all',
};

export type ActivityId = (typeof ACTIVITY_IDS)[keyof typeof ACTIVITY_IDS];

export const ACTIVITY_TITLES = {
  [ACTIVITY_IDS.ALL]: 'All',
  [ACTIVITY_IDS.RESTAURANTS]: 'Restaurant',
  [ACTIVITY_IDS.BARS]: 'Bar',
  [ACTIVITY_IDS.GROCERY]: 'Grocery Store',
  [ACTIVITY_IDS.CAFES]: 'Cafe',
  [ACTIVITY_IDS.ENTERTAINMENT]: 'Entertainment',
  [ACTIVITY_IDS.PARKS]: 'Park',
};

/* GraphQL constants for the sort field */
export const SEARCH_LIST_SORT_FIELD_TYPES = {
  LIST_PRICE: 'LIST_PRICE' as 'LIST_PRICE',
  LIST_DATE: 'LIST_DATE' as 'LIST_DATE',
};

export const SEARCH_LIST_SORT_ORDER_TYPES = {
  ASCENDING: 'ASCENDING' as 'ASCENDING',
  DESCENDING: 'DESCENDING' as 'DESCENDING',
};

export const SEARCH_LIST_SORT_OPTIONS = [
  {
    field: SEARCH_LIST_SORT_FIELD_TYPES.LIST_PRICE,
    label: 'For Sale - low to high $',
    labelAbbr: 'Lowest $',
    order: SEARCH_LIST_SORT_ORDER_TYPES.ASCENDING,
  },
  {
    field: SEARCH_LIST_SORT_FIELD_TYPES.LIST_PRICE,
    label: 'For Sale - high to low $',
    labelAbbr: 'Highest $',
    order: SEARCH_LIST_SORT_ORDER_TYPES.DESCENDING,
  },
  {
    field: SEARCH_LIST_SORT_FIELD_TYPES.LIST_DATE,
    label: 'For Sale - newest to oldest',
    labelAbbr: 'Newest',
    order: SEARCH_LIST_SORT_ORDER_TYPES.DESCENDING,
  },
  {
    field: SEARCH_LIST_SORT_FIELD_TYPES.LIST_DATE,
    label: 'For Sale - oldest to newest',
    labelAbbr: 'Oldest',
    order: SEARCH_LIST_SORT_ORDER_TYPES.ASCENDING,
  },
];

export const WATCHLIST_SORT_FIELD_TYPES = {
  BATHS: 'baths' as 'baths',
  BEDS: 'beds' as 'beds',
  SQFT: 'sqFt' as 'sqFt',
  LIST_PRICE: 'listPrice' as 'listPrice',
  AVM: 'avm' as 'avm',
  DAYS_ON_MARKET: 'daysOnMarket' as 'daysOnMarket',
  STATUS: 'status' as 'status',
};

export const PROPERTY_LIST_PAGE_SIZE = 40;
export const SEARCH_SIDEBAR_MAX_RESULTS = 10000;
export const LEGACY_SEARCH_SIDEBAR_MAX_RESULTS = 500;

/**
 * Should be same as the refresh cookie name set on the backend
 * https://git.housecanary.net/Engineering/consumer-api/blob/develop/internal/pkg/apiutils/cookies.go#L8
 */
export const REFRESH_COOKIE_NAME = 'chapi_r';
export const REFRESH_CUSTOMER_ADMIN_PORTAL_COOKIE_NAME = 'chapi_r_cap';
export const INVITE_COOKIE_NAME = 'chapi_inv';
export const TRACKING_COOKIE_NAME = 'ch_tracking';
export const CH_TRACKING_PARAM = 'utm_ch_tracking';

export const DIRECTIONS = {
  LEFT: 'left' as 'left',
  RIGHT: 'right' as 'right',
  TOP: 'top' as 'top',
  BOTTOM: 'bottom' as 'bottom',
};

/* API-returned AVM factor types for SVG icons in codebase */
export const ICON_FOR_AVM_FACTOR_TYPE = [
  'LocationAvmFactor',
  'AgeAvmFactor',
  'LotSizeAvmFactor',
  'BedroomsAvmFactor',
  'BathroomsAvmFactor',
  'LivingAreaAvmFactor',
  'PoolAvmFactor',
  'BasementAvmFactor',
];

/* Regex used to parse legal content from an external HTML page on Housecanary.com */
export const EXTERNAL_LEGAL_CONTENT_REGEX =
  /<comehomecontent>([\s\S]*)<\/comehomecontent>/i;
/* Seattle mls id */
export const NWMLS_ID = 812;
/*  */
export const MLS_IDS_TO_DISPLAY_LISTING_INFO_LOGO = [
  /* IMLS, to be eventually enabled on prod */
  232 /* IMLS, to be used during our audit pending full authorization by the MLS */, 2001,
];
export const NEREN_ID = 509;
/* currently, 632 has no_public_view turn on, which means we won't get any mls data for legal reasons */
export const RMLS_ID = 632;
/* adding 926 since 632 is a subset of 926 and we will get mls info with this check on PROD */
export const RMLS_IDX = 926;
/* adding 46 to check rmls info coming in on DEV */
export const TEST_RMLS_ID = 46;
/* For displaying Brokerage link in Footer and explicit MLS status labels */
export const HEARTLAND_MLS_ID = 294;
export const MLS_IDS_REQUIRING_BROKER_LINK_IN_FOOTER = [HEARTLAND_MLS_ID];
export const MLS_IDS_REQUIRING_EXPLICIT_LISTING_STATUS_LABELS = [
  HEARTLAND_MLS_ID,
];

export const CRIME_DATA_ATTRIBUTION = 'Crime data provided by SpotCrime.';
export const SCHOOL_DATA_ATTRIBUTION =
  'School rankings provided by Pitney Bowes.';
export const PRICE_DATA_ATTRIBUTION =
  'Price information calculated by HouseCanary.';
export const FORECAST_DATA_ATTRIBUTION = 'Forecast calculated by HouseCanary.';

export const WATCHLIST_HEADER_SECTION_KEY = {
  SAVED_HOMES: 'SAVED_HOMES' as 'SAVED_HOMES',
  SAVED_SEARCHES: 'SAVED_SEARCHES' as 'SAVED_SEARCHES',
};

export const WATCHLIST_HEADER_SECTION_CONTENT = {
  [WATCHLIST_HEADER_SECTION_KEY.SAVED_HOMES]: {
    title: 'Saved homes',
    subTitle: 'Homes that you have liked and want to follow.',
  },
  [WATCHLIST_HEADER_SECTION_KEY.SAVED_SEARCHES]: {
    title: 'Saved searches',
    subTitle:
      'Future homes that come onto the market based on your search and filter criteria.',
  },
};

export const HEADER_FOCUS_STATE_IDS = {
  HOMEPAGE: 'find-a-home',
};

export const DOMAIN_SCHEME_INITIAL_STATE: DomainTheme = {
  accessibilityFocusOutlineColor: '',
  almostBlackColor: '',
  avmBackgroundColor: '',
  authScheme: '',
  backToSiteText: null,
  darkGreyColor: '',
  displayName: '',
  favicon: '',
  googleAnalyticsId: null,
  googleTagManagerId: null,
  headerBackgroundColor: '',
  headerLinkColor: '',
  headerLinkHoverColor: '',
  navLinkSearchLabel: null,
  navLinkHomeownerLabel: null,
  navLinkWatchlistLabel: null,
  navLinkAlertsLabel: null,
  homeSiteUrl: null,
  lightGreyColor: '',
  linkOnPrimary: '',
  linkOnWhite: '',
  logo: '',
  fontFaces: null,
  mapPlaceBoundaryLayerLineColor: '',
  midGreyColor: '',
  mobileLogo: null,
  primaryButtonBorderRadius: '',
  primaryButtonFillColor: '',
  primaryColor: '',
  primaryCtaColor: '',
  secondaryButtonBorderRadius: '',
  secondaryButtonFillColor: '',
  secondaryColor: '',
  secondaryCtaColor: '',
  semiLightGreyColor: '',
  svgFillColor: '',
  textOnPrimary: '',
  textOnWhite: '',
  whiteColor: '',
  headerLinks: null,
  footer: {
    logoUrl: '',
    text: '',
  },
  disclosureText: {
    estimatedPayment: '',
    mortgageCalculator: '',
    refiCalculator: '',
  },
  customizationData: defaultCustomizationDataJSON,
  hoDashboardConfig: null,
  supportUrl: null,
  widget: [],
  customDisclaimers: {
    estimated_value_disclaimer_label: '',
    estimated_value_disclaimer_content: '',
    mortgage_calculator_disclaimer_label: '',
    mortgage_calculator_disclaimer_content: '',
  },
  grantProgram: {
    gp_header_link: {
      button_text: '',
      destination_url: '',
    },
    gp_search_results_modal: {
      program_name: '',
      header: '',
      subheader: '',
      list_header: '',
      bullet_1: '',
      bullet_2: '',
      bullet_3: '',
      button_text_and_url: {
        button_text: '',
        destination_url: '',
      },
    },
    gp_property_details_banner: {
      program_name: '',
      header: '',
      subheader: '',
      list_header: '',
      bullet_1: '',
      bullet_2: '',
      bullet_3: '',
      button_text_and_url: {
        button_text: '',
        destination_url: '',
      },
    },
    gp_srp_property_card_grant_program_label: null,
  },
  contactBrokerage: {
    headerText: '',
    headerBgColor: '',
    subheaderText: '',
    ctaHeaderText: '',
    ctaSubheaderText: '',
    ctaButtonText: '',
    ctaButtonTextColor: '',
    ctaButtonBgColor: '',
    ctaUrl: '',
    ctaNativeAppNavKey: '',
  },
  pdp: {
    claimHomeHeader: null,
    claimHomeSubHeader: null,
    claimHomeCTALabel: null,
    secondaryCtaText: null,
    secondaryCtaUrl: null,
    secondaryCtaButtonBgColor: null,
    secondaryCtaButtonTextColor: null,
    secondaryCtaNativeAppNavKey: null,
    shareModalLegalLanguage: null,
    showRentalEstimate: null,
    bannerHeadline: null,
    bannerButtonLabel: null,
    bannerDestinationUrl: null,
    bannerLoUrlTemplate: null,
    breakoutSections: null,
    iconCtaText: null,
    iconCtaUrl: null,
  },
  watchlist: {
    savedHomesLabel: null,
    savedHomesSubtext: null,
    savedSearchesLabel: null,
    savedSearchesSubtext: null,
    alertExplanationText: null,
    savedSearchesDescription: null,
  },
  searchResultsPage: {
    saveSearchButtonIcon: null,
    saveSearchButtonLabel: null,
  },
  homeownerWelcomePage: {
    hoWelcomeHeaderText: null,
    hoWelcomeSearchPlaceholder: null,
    hoWelcomeLoginLabel: null,
    hoWelcomeClaimToolsHeader: null,
    hoWelcomeImproveValueLabel: null,
    hoWelcomeImproveValueIcon: null,
    hoWelcomeCompareValueLabel: null,
    hoWelcomeCompareValueIcon: null,
    hoWelcomeLeverageValueLabel: null,
    hoWelcomeLeverageValueIcon: null,
    hoWelcomeViewPriceTrendsLabel: null,
    hoWelcomeViewPriceTrendsIcon: null,
  },
  ssoModal: {
    specialUserTypeSsoCtaText: null,
    specialUserTypeSsoCtaUrl: null,
    specialUserTypeSsoCtaButtonBgColor: null,
    specialUserTypeSsoCtaButtonTextColor: null,
    ssoAuthModalBodyText: null,
  },
  chaseQuickSearchNoResultsNativeAppNavKey: null,
  homePage: {
    homebuyingHeaderText: null,
    homebuyingSubheaderText: null,
    homebuyingSearchTabLabel: null,
    homebuyingSearchInputPlaceholder: null,

    homeownerHeaderText: null,
    homeownerSubheaderText: null,
    homeownerSearchTabLabel: null,
    homeownerSearchInputPlaceholder: null,

    homebuyingCtaHeader: null,
    homebuyingCtaDescription: null,
    homebuyingCtaButtonText: null,

    homeownerCtaHeader: null,
    homeownerCtaDescription: null,
    homeownerCtaButtonText: null,
  },
};
