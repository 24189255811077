import PillButton from '@client/components/generic/PillButton';
import defaultTheme from '@client/css-modules/YourTeam/YourTeamTriggerButton.css';
import { YourTeamModalType } from '@client/store/types/your-team';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';

import { useIsLOTabDisabled } from '@client/hooks/your-team.hooks';
import HelpIcon from '@client/inline-svgs/help-icon';
import { getYourTeam } from '@client/store/selectors/cobranding.selectors';
import { selectConstrainedToPlaceHasNoMLSCoverage } from '@client/store/selectors/search.selectors';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
type Props = {
  theme: Theme;
  openYourTeamModal: (modalType: YourTeamModalType) => void;
  dataEventName?: string;
  dataParentEventName?: string;
};

function YourTeamTriggerButton({
  theme,
  openYourTeamModal,
  dataEventName,
  dataParentEventName,
}: Props) {
  const yourTeam = useSelector(getYourTeam);
  const customButtonText = yourTeam?.button_text;
  const customButtonIcon = yourTeam?.button_icon;
  const isLOTabDisabled = useIsLOTabDisabled();
  const isLOTabEnabled = !isLOTabDisabled;
  const shouldAdjustButtonPlacement = useSelector(
    selectConstrainedToPlaceHasNoMLSCoverage
  );

  return (
    <PillButton
      className={classNames(theme.YourTeamTriggerButton, {
        [theme.YourTeamTriggerButtonAdjusted]: shouldAdjustButtonPlacement,
      })}
      data-event-name={dataEventName}
      data-parent-event-name={dataParentEventName}
      dataHcName="your-team-button"
      customBackgroundColor="#ffffff"
      customTextColor="#4a4a4a"
      onClick={() => openYourTeamModal(isLOTabEnabled ? 'lo' : 'agent')}
    >
      {customButtonIcon ? (
        <img
          src={customButtonIcon}
          role="presentation"
          alt=""
          className={theme.ModalButtonSVG}
        />
      ) : (
        <HelpIcon aria-hidden className={theme.ModalButtonSVG} />
      )}{' '}
      {customButtonText ? customButtonText : 'Have Questions?'}
    </PillButton>
  );
}

export default themr(
  'YourTeamTriggerButton',
  defaultTheme
)(YourTeamTriggerButton);
